<template>
    <main>
        <h2 class="torder-title">
            {{ $t("DESTINATION.ORDER") }}
        </h2>
        <div class="d-flex w-100">
            <div class="text-center w-25">
                {{ $t("DESTINATION.ORDER") }}
            </div>
            <div class="text-center w-25">
                {{ $t("COMMON.NAME") }}
            </div>
            <div class="text-center w-25">
                {{ $t("COMMON.DATES") }}
            </div>
            <div class="text-center w-25">
                {{ $t("REPORT_SALES.STATUS") }}
            </div>
        </div>
        <div class="torder-tr">
            <div class="text-center w-25">
                645412
            </div>
            <div class="text-left w-25">
                <p>Barcelona, Hiton</p>
                <p>5 star hotel.... </p>
            </div>
            <div class="text-center w-25">
                <p>21/10/2020</p>
                <p>25/10/2020</p>
            </div>
            <div class="text-center w-25 color-green">
                Booked
            </div>
        </div>
        <div class="torder-tr">
            <div class="text-center w-25">
                645412
            </div>
            <div class="text-left w-25">
                <p>Barcelona, Hiton</p>
                <p>5 star hotel.... </p>
            </div>
            <div class="text-center w-25">
                <p>21/10/2020</p>
                <p>25/10/2020</p>
            </div>
            <div class="text-center w-25 color-red1">
                Canceld
            </div>
        </div>
    </main>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
p{
    margin: 0;
}
.torder-title {
    margin: 10px 20px 0;
}
.torder-tr {
    display: flex;
    align-items: center;
    background-color: #fff;
    margin-bottom: 3px;
}
</style>